import { ComponentType } from 'react'
import { isObject } from 'lodash'
import { useTranslation } from 'react-i18next'
import useDictionary from '../utils/useDictionary'
import Skeleton from '@mui/material/Skeleton'
import useData from '../utils/useData'
import { AmalfiVisualizationType } from '../types/componentTypes'

function amalfiVisualizationHOC (WrappedComponent: ComponentType<any>) {
  return function ({
    element
  }: {
    element: AmalfiVisualizationType
  }): JSX.Element {
    const { t } = useTranslation()
    const dictionary = useDictionary(element.dictName, element.applicationID)
    const { data, error } = useData(element)
    if (error !== undefined) {
      console.error(error)
      return (
        <Skeleton
          variant='rectangular'
          width='96%'
          height='100'
          style={{ margin: 10 }}
        />
      )
    }
    if (data === 'empty') {
      console.warn('Returning "empty" for data is deprecated.')
      return <h1 color='error'>{t('error.no_data')}</h1>
    }
    if (dictionary.loading) {
      return (
        <Skeleton
          variant='rectangular'
          width='96%'
          height='100'
          style={{ margin: 10 }}
        />
      )
    }
    let dict: { [key: string]: string } | undefined
    if (dictionary.dictionary !== null) {
      if (isObject(dictionary.dictionary.content.list)) {
        dict = dictionary.dictionary.content.list
      } else {
        dict = dictionary.dictionary.content as any as { [key: string]: string }
      }
    }

    const div = element.div ?? { height: 100 }
    if (data === undefined) {
      return (
        <div style={div}>
          <Skeleton
            variant='rectangular'
            width='96%'
            height='100%'
            style={{ margin: 10 }}
          />
        </div>
      )
    }

    return (
      <div style={div}>
        <WrappedComponent
          data={data}
          {...element.props}
          dictionary={dict}
          renderDependencies={[data]}
        />
      </div>
    )
  }
}

export default amalfiVisualizationHOC
