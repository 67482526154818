import { useAsync } from 'react-use'
import { isObject } from 'lodash'
import { getAppIds, http } from '../authentification/authState'

export interface DictionaryContent {
  list: { [key: string]: string }
}

export interface Dictionary {
  name: string
  language: string
  content: DictionaryContent
}

const dictionaryCache: { [key: string]: Dictionary } = {}
async function memoizedDictionary (
  appId: string,
  dictName: string,
  language: string
): Promise<Dictionary> {
  if (dictionaryCache[dictName + language] !== undefined) {
    return dictionaryCache[dictName + language]
  }
  const dict = await (
    await http(appId)
  ).get<Dictionary>(
    `dictionaries/${dictName}/?language=${language}&rnd=${Math.random()
      .toString()
      .slice(10)}`
  )
  dictionaryCache[dictName + language] = dict.data
  return dictionaryCache[dictName + language]
}

export async function getDict (
  dictName: string | undefined,
  appId: string
): Promise<{
    searchInDictionary: (key?: string | undefined, strict?: boolean) => string
    dictionary: Dictionary | null
  }> {
  const lang = localStorage.getItem('i18nextLng') ?? 'ca' // TODO: - getLanguage
  let dict: Dictionary | null = null
  try {
    if (dictName !== undefined) {
      dict = await memoizedDictionary(appId, dictName, lang)
    }
  } catch (e) {
    console.warn(`Dictionary ${dictName ?? ''} not found.`)
  }
  const searchDict = (key?: string, strict = false): string => {
    if (key === undefined) {
      return ''
    }
    if (dict !== null) {
      // This is to ensure compatibility with all dictionary formats.
      let content: { [id: string]: string } = dict.content as never as {
        [id: string]: string
      }
      if ('list' in dict.content && isObject(dict.content.list)) {
        content = dict.content.list
      }
      if (content[key] !== undefined) {
        return content[key]
      }
      if (strict) {
        throw new Error(
          `Key ${key ?? ''} not found in dictionary ${dictName ?? ''}`
        )
      }
      return key ?? ''
    }
    if (strict) {
      throw new Error(`Dictionary ${dictName ?? ''} has not been loaded.`)
    }
    return key ?? ''
  }
  return { searchInDictionary: searchDict, dictionary: dict }
}

/**
 *
 * @param dictionaryName The name of the dictioanry. When the name changes the dictionary is reloaded.
 * @returns A function that gets a key and a strict mode flag. The strict mode flag will throw an exception
 * if the dictionary does not contain the key. If the key is undefined the function returns an empty string to avoid
 * having to set as string all the time.
 */
function useDictionary (
  dictionaryName?: string,
  appId?: string
): {
    loading: boolean
    error: Error | undefined
    searchInDictionary: (key?: string | undefined, strict?: boolean) => string
    dictionary: Dictionary | null
  } {
  const { loading, error, value } = useAsync(async () => {
    if (appId === undefined) {
      appId = (await getAppIds())[0]
      console.warn(`Dictionary ${dictionaryName ?? ''} has no appId.`)
    }
    return await getDict(dictionaryName, appId)
  }, [dictionaryName])
  if (value === undefined) {
    return {
      loading,
      error,
      searchInDictionary: (key?: string, strict = false) => key ?? '',
      dictionary: null
    }
  }
  return {
    loading,
    error,
    searchInDictionary: value.searchInDictionary,
    dictionary: value.dictionary
  }
}

export default useDictionary
